module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Husseyexplores","short_name":"Husseyexplores","start_url":"/","background_color":"#ffffff","theme_color":"#fff7fa","display":"minimal-ui","icon":"content/assets/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e88c5c0d9b195354864f9828c6232852"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
